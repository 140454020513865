/**
 * Msa Notification Service UI
 *
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum NotificationTypeDto {
    Request = 'REQUEST',
    Duty = 'DUTY',
    General = 'GENERAL',
    Support = 'SUPPORT',
    MilitaryData = 'MILITARY_DATA'
}

