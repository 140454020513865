import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { MsaNotificationConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { NotificationUIRestService } from './api/notificationUI.service';
import { SupportFormUIRestService } from './api/supportFormUI.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    NotificationUIRestService,
    SupportFormUIRestService ]
})
export class MsaNotificationApiModule {
    public static forRoot(configurationFactory: () => MsaNotificationConfiguration): ModuleWithProviders<MsaNotificationApiModule> {
        return {
            ngModule: MsaNotificationApiModule,
            providers: [ { provide: MsaNotificationConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: MsaNotificationApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('MsaNotificationApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
